// import store from '@/state/store'
import { authenticationGuard } from '@/auth/authenticationGuard';
import vueApp from "@/main";

export default [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import('../views/pages/account/login')
  },
  {
    path: '/forgot-password',
    name: 'Forgot-password',
    component: () =>
      import('../views/pages/account/forgot-password'),
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        if (!vueApp.$auth.user.onboarding) {
          next({ name: 'Artist Dashboard' })
        } else {
          next()
        }
      },
    },
    beforeEnter: authenticationGuard,
    component: () =>
      import('../views/pages/utility/onboarding')
  },
  {
    path: '/',
    name: 'Artist Dashboard',
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (vueApp.$auth.user.onboarding) {
          next({ name: 'Onboarding' })
        } else {
          next()
        }
      },
    },
    beforeEnter: authenticationGuard,
    component: () =>
      import('../views/pages/dashboard/index')
  },
  {
      path: '/analytics',
      name: 'Analytics',
      beforeEnter: authenticationGuard,
      component: () =>
        import('../views/pages/analytics/echart/index')
  },
  {
      path: '/research',
      name: 'Research',
      beforeEnter: authenticationGuard,
      component: () =>
        import('../views/pages/research')
  },
  {
      path: '/tracks',
      name: 'Tracks',
      beforeEnter: authenticationGuard,
      component: () =>
        import('../views/pages/tracks')
  },
  {
      path: '/campaign',
      name: 'Campaign',
      beforeEnter: authenticationGuard,
      component: () =>
        import('../views/pages/campaign-detail')
  },
  {
      path: '/campaign-checkout',
      name: 'Campaign Checkout',
      beforeEnter: authenticationGuard,
      component: () =>
        import('../views/pages/campaign-checkout')
  },
  {
    path: '/error-404',
    name: 'Error-404',
    // beforeEnter: authenticationGuard,
    component: () =>
      import('../views/pages/utility/error-404')
    },
  {
    path: '/error-500',
    name: 'Error-500',
    // beforeEnter: authenticationGuard,
    component: () =>
      import('../views/pages/utility/error-500')
    },
    { path: '*', redirect: '/error-404' },
]
