import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
// import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'
// import VueGtag from "vue-gtag";

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'

import "@/assets/scss/app.scss";
import "@/assets/css/global.css";

import { Auth0Plugin } from './auth/auth0-plugin';
import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

// Install the authentication plugin
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_SUBS_ARTIST_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_SUBS_ARTIST_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_SUBS_ARTIST_AUTH0_API_AUDIENCE,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl ?
      appState.targetUrl :
      `${window.location.pathname}/artist-dashboard`,
    );
  },
});

// Vue.use(VueGtag, {
//   config: { id: "G-5Z2XZKNWEJ" }
// });

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
//     libraries: 'places',
//   },
//   installComponents: true
// })
Vue.component('apexchart', VueApexCharts)

Vue.mixin({
  methods: {
    abbreviateNumber: (number, decPlaces = 2) => {
      decPlaces = Math.pow(10, decPlaces)
      var abbrev = ['k', 'M', 'B', 'T']
      for (var i = abbrev.length - 1; i >= 0; i--) {
        var size = Math.pow(10, (i + 1) * 3)
        if (size <= number) {
          number = Math.round((number * decPlaces) / size) / decPlaces
          if (number == 1000 && i < abbrev.length - 1) {
            number = 1
            i++
          }
          number += abbrev[i]
          break
        }
      }
      return number
    },
    activityIconMap: (activity) => {
      let activityIconMap = {
        'profile-updated': 'fas fa-user-edit',
        'track-created': 'ri-folder-music-fill',
        'track-updated': 'ri-file-music-fill',
        'campaign-created': 'ri-global-line',
        'campaign-updated': 'ri-global-line',
        'campaign-renewed': 'ri-repeat-2-fill',
        'campaign-ended': 'ri-global-line',
        'campaign-track-submitted': 'ri-play-list-fill',
        'campaign-track-acquired': 'ri-play-list-add-fill',
        'campaign-track-declined': 'ri-stop-mini-fill'
      }
      return activityIconMap[activity]
    },
    platformIconMap: (platform) => {
      let platformIconMap = {
        // 'spotify': 'ri-spotify-fill', // remix
        // 'apple': 'ri-apple-fill',
        // 'amazon': 'ri-amazon-fill',
        // 'tiktok': 'fa-brands fa-tiktok',
        'spotify': 'fab fa-spotify', // font-awesome
        'apple': 'fab fa-apple',
        'amazon': 'fab fa-amazon',
        'tiktok': 'fab fa-tiktok',
      }
      return platformIconMap[platform]
    },
    handleQuerySort: (sortQuery) => {
      try {
        const toJSONString = ("{" + sortQuery + "}").replace(/(\w+:)|(\w+ :)/g, (matched => {
          return '"' + matched.substring(0, matched.length - 1) + '":';
        }));
        return JSON.parse(toJSONString);
      } catch (err) {
        return JSON.parse("{}");
      }
    }
  },
})

export const eventBus = new Vue(); // added line

const vueApp = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default vueApp;
